/*
 * @Author: xr
 * @Date: 2022-03-01 15:37:34
 * @LastEditors: xr
 * @LastEditTime: 2022-03-04 17:17:00
 * @FilePath: \postStation-office\src\mixins\page.js
 */
export const Page = {
  data() {
    return {
      // 页数
      pageNo: 1,
      // 一页行数
      pageSize: 10,
      // 总数
      total: 1,
      // 搜索条件
      searchData: {}
    }
  },
  methods: {
    /**
     * @description:翻页
     * @param {*} current
     */
    onChange(current) {
      this.pageNo = current
      this.getList()
    },
    /**
     * @description:搜索组件传出参数
     * @param {object} param
     */
    search(param) {
      this.searchData = param
      // if (this.stationId) {
      //   param.stationId = this.stationId
      // }
      // 若为子站添加子站
      console.log('param :>> ', param)
      const mainResearchAreas = param.mainResearchAreas ? '&mainResearchAreas=' + param.mainResearchAreas : ''
      const name = param.name ? '&name=' + param.name : ''
      if (this.stationId) {
        window.open(window.location.origin + window.location.pathname + '?' + mainResearchAreas + name + '&stationId=' + this.stationId, '_self')
      } else {
        window.open(window.location.origin + window.location.pathname + '?' + mainResearchAreas + name, '_self')
      }
      // // 在地址栏存参数
      // this.$router.replace({ path: window.location.pathname, query: param })
      this.getList()
    }
  }
}
