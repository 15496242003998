/*
 * @Author: xr
 * @Date: 2022-02-24 16:11:59
 * @LastEditors: xr
 * @LastEditTime: 2022-02-24 16:16:43
 * @FilePath: \postStation-office\src\api\project.js
 */
import request from '../utils/request'

// 获取案例
export const getProjectList = query => {
  return request({
    url: '/sys/website/DemandInformationManagementController/getProjects',
    method: 'get',
    params: query
  })
}

// 按照需求名称关键词搜索和应用领域字典搜索分页
export const demandSearch = query => {
  return request({
    url: '/sys/website/DemandInformationManagementController/queryPageListDemand',
    method: 'get',
    params: query
  })
}

// 根据新闻id查新闻详情
export const newsDetail = query => {
  return request({
    url: '/sys/website/news/detail',
    method: 'get',
    params: query
  })
}

// 获取重点项目
export const project = query => {
  return request({
    url: '/sys/website/DemandInformationManagementController/queryPageListAllKeyProjects',
    method: 'get',
    params: query
  })
}

// 通过领域查询所有项目或者需求
export const domainQuery = query => {
  return request({
    url: '/sys/website/DemandInformationManagementController/queryPageListByDomain',
    method: 'get',
    params: query
  })
}

// 根据id查询某一条项目或者需求的信息
export const domainQueryId = query => {
  return request({
    url: '/sys/website/DemandInformationManagementController/queryById',
    method: 'get',
    params: query
  })
}

// 根据行业信息id查行业信息详情
export const industryDetail = query => {
  return request({
    url: '/sys/website/industryInformation/detail',
    method: 'get',
    params: query
  })
}
