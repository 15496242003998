<!--
 * @Author: xr
 * @Date: 2022-02-11 17:01:12
 * @LastEditors: xr
 * @LastEditTime: 2022-03-11 11:32:27
 * @FilePath: \postStation-office\src\components\layout\link.vue
-->
<template>
  <div class="link-outer sub-component-background-color">
    <div class="link-box" />
    <a-empty v-if="listData.length===0" class="empty-data"/>
    <div v-else @click="clickProps($event)">
      <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp">
        <ul class="ul-item">
          <li class="li-item" v-for="item in listData" :key="item.id">
            <img :data-link="JSON.stringify(item.linkAddress)" :src="item.linkPicture" alt="友情链接">
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import { getLink } from '@/api/index'
import { Station } from '@/mixins/station'

export default {
  name: 'Link',
  mixins: [Station],
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      listData: [],
      classOption: {
        limitMoveNum: 1,
        direction: 2,
        step: 0.8
      }
    }
  },
  created() {
    // 判断子站
    this.getStationId()
    getLink({ stationId: this.stationId }).then(res => {
      this.listData = res.result ? res.result.records : []
    })
  },
  methods: {
    /**
     * @description:跳转
     * @param {*} e
     */
    clickProps(e) {
      const link = JSON.parse(e.target.dataset.link)
      if (link) {
        window.open(link, '_blank')
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.link-outer{
  width: 990px;
  margin: 0 auto;
  min-height: 115px;
  border: 1px solid #bbd3ef;
  border-top: 1px solid #5f95c3;
  margin-bottom:10px;

  .link-box{
    width: 988px;
    height: 39px;
    background: url("../../assets/img/link_bg.jpg") no-repeat;
    background-size: 100%;
  }
}
.warp {
  width: 948px;
  margin: 0 auto;
  overflow: hidden;
  padding: 16px 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    &.ul-item {
      display: flex;
      cursor: pointer;
      .li-item {
        margin-right: 10px;
        img{
          width: 120px;
          height:60px;
          background-size: cover;
        }
      }
    }
  }
}

</style>
