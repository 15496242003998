/*
 * @Author: xr
 * @Date: 2022-03-01 16:16:26
 * @LastEditors: xr
 * @LastEditTime: 2022-03-04 16:35:30
 * @FilePath: \postStation-office\src\mixins\station.js
 */
export const Station = {
  data() {
    return {
      // 子站ID
      stationId: '',
      stationName: ''
    }
  },
  methods: {
    /**
     * @description:获取子站ID
     */
    getStationId() {
      // console.log('this.$route.query :>> ', this.$route.query)
      this.stationId = this.$route.query.stationId
      this.stationName = this.$route.query.stationName
      // console.log('stationId :>> ', this.stationId)
      // this.stationId = ''
    }
  }
}
