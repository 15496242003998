<!--
 * @Author: xr
 * @Date: 2022-02-16 11:02:29
 * @LastEditors: xr
 * @LastEditTime: 2022-03-03 11:25:01
 * @FilePath: \postStation-office\src\components\search.vue
-->
<template>
  <div>
    <a-row class="search">
      <a-card :bordered="false" style="background:#fafafa;display:flex; justify-content:center;">
        <a-form-model :form="form" layout="inline">
          <a-form-model-item label="关键词">
            <a-input v-model="form.name" allowClear placeholder="请输入关键字"/>
          </a-form-model-item>
          <a-form-model-item :label="searchLable">
            <a-select v-model="form.mainResearchAreas" allow-clear style="width:180px;" :placeholder="'请选择'+searchLable">
              <a-select-option v-for="item in area" :key="item.value" :value="item.value">{{item.text}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-card>
    </a-row>
  </div>
</template>
<script>
import { getDict } from '@/api/index'
// import { demandSearch } from '@/api/project'
export default {
  name: 'Search',
  props: {
    searchLable: {
      type: String,
      default: () => '应用领域'
    }
  },
  data() {
    return {
      // 表单
      form: {
        mainResearchAreas: undefined,
        name: ''
      },
      // 领域
      area: []
    }
  },
  methods: {
    /**
     * @description: 提交
     */
    search() {
      this.$emit('search', this.form)
    }
  },
  created() {
  },
  activated() {
    getDict('application_field').then(res => {
      this.area = res.result
    })
  }
}
</script>
<style lang="scss">
.search{
  margin-bottom: 10px;
}
</style>
