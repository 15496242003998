<!--
 * @Author: xr
 * @Date: 2022-01-26 09:16:33
 * @LastEditors: xr
 * @LastEditTime: 2022-03-11 09:21:17
 * @FilePath: \postStation-office\src\views\result.vue
-->
<template>
   <div class="main">
    <div class="main_box">
      <a-row type="flex" :gutter="20" justify="space-between" style="padding:0px 10px 10px;">
        <a-col :span="24">
          <a-row style="min-height:68vh;" class="sub-component-background-color">
            <search-box ref="searchModal" @search="search" />
            <div v-if="data.length===0" style="padding-top: 30px">
              <a-empty class="empty-data"/>
            </div>
            <a-table v-else rowKey="id" :columns="columns" :data-source="data" size="middle" :customRow="Rowclick" :pagination="false" style="cursor: pointer !important;">
              <span slot="name" class="table-header">专利名称</span>
              <a slot="name" slot-scope="text">{{ text }}</a>
              <span slot="area" class="table-header">应用领域</span>
              <a slot="area" slot-scope="text">{{ text }}</a>
              <span slot="price" class="table-header">参考价格</span>
              <a slot="price" slot-scope="text">{{ text }}</a>
            </a-table>
          </a-row>
          <a-row type="flex" justify="center">
            <a-pagination v-show="data.length" v-model="pageNo" :total="total" :default-page-size="pageSize" @change="onChange" style=" margin:20px 40px 10px 0px"/>
          </a-row>
        </a-col>
      </a-row>
      <!-- 友情链接 -->
      <link-box />
    </div>
   </div>
</template>

<script>
import searchBox from '@comp/search'
import linkBox from '@comp/layout/link'
// import FindDemand from '@comp/findDemand'
// import { getDict } from '@/api/index'
import { demandSearch } from '@/api/project'
import { Page } from '@/mixins/page'

export default {
  name: 'Result',
  mixins: [Page],
  components: {
    searchBox,
    linkBox
    // FindDemand
  },
  data() {
    return {
      columns: [
        {
          dataIndex: 'productName',
          key: 'name',
          slots: { title: 'name' },
          width: '50%',
          ellipsis: true,
          scopedSlots: { customRender: 'name' }
        },
        {
          dataIndex: 'applicationArea_dictText',
          key: 'area',
          slots: { title: 'area' },
          align: 'center',
          width: '30%',
          ellipsis: true,
          scopedSlots: { customRender: 'area' }
        },
        {
          dataIndex: 'price',
          key: 'price',
          slots: { title: 'price' },
          align: 'center',
          width: '20%',
          scopedSlots: { customRender: 'price' }
        }
      ],
      data: [
      ],
      technicalProjectContent: [],
      stationId: '',
      type: 2
    }
  },
  methods: {
    /**
     * @description:表格点击事件
     * @param {object} record
     * @param {number} index
     */
    Rowclick(record, index) {
      const _this = this
      return {
        on: {
          click: () => {
            if (this.stationId !== '') {
              _this.$router.push({ name: 'detail', params: record, query: { stationId: this.stationId }})
            } else {
              _this.$router.push({ name: 'detail', params: record })
            }
          }
        }
      }
    },
    /**
     * @description:获取列表
     */
    getList() {
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        mainResearchAreas: this.searchData.mainResearchAreas,
        name: this.searchData.name,
        type: this.type,
        stationId: this.stationId
      }
      // 按照需求名称关键词搜索和应用领域字典搜索分页
      demandSearch(params).then(res => {
        this.data = res.result.records
        this.total = res.result.total * 1
      })
    }

  },
  activated() {
    // 请求参数赋值
    this.stationId = this.$route.query.stationId
    this.searchData = this.$route.query
    this.getList()
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 无参数清除请求参数
      if (JSON.stringify(to.query) === '{}') {
        vm.$refs.searchModal.form.name = ''
        vm.$refs.searchModal.form.mainResearchAreas = ''
      } else {
        // 刷新后搜索组件赋值
        vm.$refs.searchModal.form.name = to.query.name
        vm.$refs.searchModal.form.mainResearchAreas = to.query.mainResearchAreas
      }
      // 清空剩余数据
      vm.data = []
      vm.total = 0
    })
  }

}
</script>

<style scoped>
::v-deep .ant-table-thead th{
  /* min-width: 10px;
  min-height: 10px; */
  background: #85C0EE;
}
.table-header{
  color: #000;
  font-size: 16px;
  font-weight: bold;
}

@import "../assets/css/newsInformation.css";
</style>
